'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import useMediaQuery from 'hooks/useMediaQuery';

import styles from './Stagger.module.scss';

const Stagger = ({
    inView = false,
    children,
    className = null,
    threshold = 0,
    ...props
}) => {
    const ref = useRef(null);
    const [isInView, setIsInView] = useState(inView);

    const isMedium = useMediaQuery('(min-width: 768px)');
    const isLarge = useMediaQuery('(min-width: 1024px)');
    const isXLarge = useMediaQuery('(min-width: 1440px)');

    const rootMargin = isMedium
        ? isLarge
            ? isXLarge
                ? '-250px'
                : '-200px'
            : '-125px'
        : '-50px';

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin,
        threshold,
    });

    useEffect(() => {
        if (intersection && intersection.isIntersecting) {
            setIsInView(true);
        }
    }, [intersection]);

    return (
        <div
            ref={ref}
            className={cx(styles.stagger, className, {
                [styles.isInView]: isInView,
            })}
            {...props}
        >
            {children}
        </div>
    );
};

export { default as Child } from './Child';

Stagger.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    threshold: PropTypes.number,
    inView: PropTypes.bool,
};

export default Stagger;
