'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Stagger.module.scss';

const Child = ({
    animation = 'fadeUp',
    children,
    className,
    delay = 0.18,
    duration = 0.3,
    order,
    as: Tag = 'div',
    ...props
}) => {
    return (
        <Tag
            className={cx(styles.child, className, styles[animation])}
            style={{
                animationDelay: `${order * delay}s`,
                animationDuration: `${duration}s`,
            }}
            {...props}
        >
            {children}
        </Tag>
    );
};

Child.propTypes = {
    animation: PropTypes.oneOf(['fadeUp', 'disabled']),
    children: PropTypes.node,
    className: PropTypes.string,
    delay: PropTypes.number,
    duration: PropTypes.number,
    order: PropTypes.number.isRequired,
    as: PropTypes.string,
};

export default Child;
