import contact from './_contact';
import holiday from './_holiday';
import klaviyo from './_klaviyo';
import restaurant from './_restaurant';

export default {
    restaurant,
    contact,
    klaviyo,
    holiday,
};
