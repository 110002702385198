'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useIntersection, usePrevious } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import useDocumentVisibility from 'hooks/useDocumentVisibility';
import { blockRootProps, getColor } from 'utils';

import Slide from './Slide';
import defaultImages from './default';

import styles from './BlockWhereToBuy.module.scss';

const animationStates = {
    IDLE: 'idle',
    ENTER: 'enter',
    PLAYING: 'playing',
    PAUSED: 'paused',
};

const BlockWhereToBuy = ({
    __typename,
    bodyCopy,
    images = [],
    backgroundColor,
    lastBlock,
}) => {
    bodyCopy ??=
        'From bold cheddar to extra creamy ice cream, find Tillamook near you';
    images ??= [];

    const ref = useRef(null);

    const [isInView, setIsInView] = useState(false);
    const [animationState, setAnimationState] = useState(animationStates.IDLE);

    const previousAnimationState = usePrevious(animationState);
    const documentVisibility = useDocumentVisibility();

    const items = (
        images?.length >= 5 ? [...images] : [...defaultImages]
    ).reverse();

    // We initialize at four because the first item in the array needs to be in position three at the start of the animation
    const [activeIndex, setActiveIndex] = useState(4);

    useEffect(() => {
        if (animationState !== animationStates.PLAYING) {
            return;
        }

        const interval = setInterval(() => {
            setActiveIndex(prevActivIndex => {
                return prevActivIndex < items.length - 1
                    ? prevActivIndex + 1
                    : 0;
            });
        }, 3000);

        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationState]);

    useEffect(() => {
        if (isInView) {
            // Previously IDLE - need to enter
            if (previousAnimationState === animationStates.IDLE) {
                setAnimationState(animationStates.ENTER);

                // Set a timeout to trigger the playing state
                setTimeout(() => {
                    setAnimationState(animationStates.PLAYING);
                }, 1200);
            }

            // Previously PAUSED - need to play animation now
            if (previousAnimationState === animationStates.PAUSED) {
                setAnimationState(animationStates.PLAYING);
            }
        } else {
            if (previousAnimationState === animationStates.PLAYING) {
                setAnimationState(animationStates.PAUSED);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView]);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    });

    useEffect(() => {
        intersection?.isIntersecting && documentVisibility === 'visible'
            ? setIsInView(true)
            : setIsInView(false);
    }, [intersection, documentVisibility]);

    return (
        <section
            {...blockRootProps(null, __typename)}
            ref={ref}
            className={cx(styles.root, {
                [styles.lastBlock]: lastBlock,
                [styles.onEnter]: animationState === animationStates.ENTER,
                [styles.afterEnter]:
                    animationState === animationStates.PLAYING ||
                    animationState === animationStates.PAUSED,
            })}
            style={{
                '--background-color': backgroundColor
                    ? getColor(backgroundColor)
                    : getColor('white'),
            }}
        >
            <div className={styles.inner}>
                <div className={styles.lockup}>
                    <Stagger className={styles.lockupInner}>
                        <div className={styles.icon} />
                        <StaggerChild order={0}>
                            <Text.Theme
                                className={styles.heading}
                                config={{
                                    default: {
                                        baseTheme: 'displayMedium',
                                        themes: {
                                            large: 'displayXLarge',
                                        },
                                    },
                                }}
                                as="h2"
                            >
                                Find us
                                <br />
                                near you
                            </Text.Theme>
                        </StaggerChild>
                        <StaggerChild order={1}>
                            <Text.Theme
                                className={styles.bodyCopy}
                                as="p"
                                config={{
                                    default: {
                                        baseTheme: 'bodySmall',
                                        themes: {
                                            large: 'bodyMedium',
                                        },
                                    },
                                }}
                            >
                                {bodyCopy}
                            </Text.Theme>
                        </StaggerChild>
                        <StaggerChild className={styles.linkWrapper} order={2}>
                            <ButtonCTA
                                className={styles.link}
                                text="Where to buy"
                                link={{
                                    slug: 'where-to-buy',
                                }}
                                style="filled-blue-cream"
                                iconType="location"
                            />
                        </StaggerChild>
                    </Stagger>
                </div>
                <div className={styles.track}>
                    {items?.map((item, index) => {
                        const slot = (index + activeIndex) % items.length;

                        return (
                            <Slide
                                key={index}
                                slot={slot}
                                animationState={animationState}
                                {...item}
                            />
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

BlockWhereToBuy.propTypes = {
    __typename: PropTypes.string,
    bodyCopy: PropTypes.string,
    images: PropTypes.array,
    backgroundColor: PropTypes.string,
    lastBlock: PropTypes.bool,
};

export default BlockWhereToBuy;
