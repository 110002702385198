import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './RichText.module.scss';

const propTypes = {
    children: PropTypes.node,
};

// MARKS
export const Bold = ({ children = null }) => {
    return <b className={styles.bold}>{children}</b>;
};

export const Italic = ({ children = null }) => {
    return <i className={styles.italic}>{children}</i>;
};

export const Underline = ({ children = null }) => {
    return <u className={styles.underline}>{children}</u>;
};

Bold.propTypes = propTypes;

Italic.propTypes = propTypes;

Underline.propTypes = propTypes;

// BLOCKS
export const BodyText = ({ children = null }) => (
    <Text
        as="p"
        baseTheme="bodySmall"
        themes={{ large: 'bodyMedium' }}
        className={styles.paragraph}
        fixWidows
    >
        {children}
    </Text>
);

export const OrderedList = ({ children = null }) => (
    <ol className={styles.orderedList}>{children}</ol>
);

export const UnorderedList = ({ children = null }) => (
    <ul className={styles.unorderedList}>{children}</ul>
);

export const ListItem = ({ children = null }) => (
    <li className={styles.listItem}>{children}</li>
);

export const HeadingOne = ({ children = null }) => (
    <Text
        as="h1"
        baseTheme="displayLarge"
        className={cx(styles.heading, styles.largeHeading)}
    >
        {children}
    </Text>
);

export const HeadingTwo = ({ children = null }) => (
    <Text
        as="h2"
        baseTheme="headingXLarge"
        className={cx(styles.heading, styles.largeHeading)}
    >
        {children}
    </Text>
);

export const HeadingThree = ({ children = null }) => (
    <Text
        as="h3"
        baseTheme="headingLarge"
        className={cx(styles.heading, styles.largeHeading)}
    >
        {children}
    </Text>
);

export const HeadingFour = ({ children = null }) => (
    <Text as="h4" baseTheme="headingMedium" className={styles.heading}>
        {children}
    </Text>
);

export const HeadingFive = ({ children = null }) => (
    <Text as="h5" baseTheme="headingSmall" className={styles.heading}>
        {children}
    </Text>
);

export const HeadingSix = ({ children = null }) => (
    <Text as="h6" baseTheme="headingXSmall" className={styles.heading}>
        {children}
    </Text>
);

export const Blockquote = ({ children = null }) => (
    <Text as="blockquote" baseTheme="bodySmall" className={styles.blockquote}>
        <SvgIcon type="quote" />
        {children}
    </Text>
);

BodyText.propTypes = propTypes;

OrderedList.propTypes = propTypes;

UnorderedList.propTypes = propTypes;

ListItem.propTypes = propTypes;

Blockquote.propTypes = propTypes;

HeadingOne.propTypes = propTypes;

HeadingTwo.propTypes = propTypes;

HeadingThree.propTypes = propTypes;

HeadingFour.propTypes = propTypes;

HeadingFive.propTypes = propTypes;

HeadingSix.propTypes = propTypes;
