import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

const Portal = ({ selector, children }) => {
    selector ??= '#__portal__';

    const [element, setElement] = useState(null);

    useEffect(() => {
        const element = document.querySelector(selector);
        setElement(element);
    }, [selector]);

    if (!element) {
        return null;
    }

    return ReactDOM.createPortal(children, element);
};

Portal.propTypes = {
    selector: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Portal;
