import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';

import styles from './ButtonCircle.module.scss';

const ButtonCircle = ({
    as,
    className,
    theme,
    iconType,
    children,
    ...props
}) => {
    const Tag = as ?? 'button';

    return (
        <Tag className={cx(styles.root, styles[theme], className)} {...props}>
            <SvgIcon className={styles[`icon-${iconType}`]} type={iconType} />
            <span className="sr-only">{!children && `Button ${theme}`}</span>
            {children}
        </Tag>
    );
};

ButtonCircle.propTypes = {
    as: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    theme: PropTypes.oneOf([
        'small-fill-blue',
        'small-fill-cream',
        'small-fill-gold',
        'small-fill-white',
        'small-fill-orange',
        'small-fill-bluedark',
        'small-fill-creamdark',
        'large-thick-blue',
        'large-thick-cream',
    ]),
    iconType: PropTypes.oneOf([
        'chevronRight',
        'chevronLeft',
        'chevronDown',
        'playVideo',
        'arrowRight',
        'arrowLeft',
        'arrowRightRounded',
        'plus',
        'minus',
        'close',
    ]),
};

export default ButtonCircle;
